





























































import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import AddUnit from '@/components/goods/add-unit.vue'
import { apiUnitDel, apiUnitLists } from '@/api/goods'
import { RequestPaging } from '@/utils/util'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsDialog,
        LsPagination,
        AddUnit,
        ExportData
    }
})
export default class Unit extends Vue {
    $refs!: { addUnit: any }
    pager = new RequestPaging()
    queryObj = {
        name: ''
    }
    form: any = {
        name: '',
        sort: ''
    }
    apiUnitLists = apiUnitLists
    handleAdd() {
        this.form = {
            name: '',
            sort: ''
        }
        this.$refs.addUnit.openDialog()
    }
    handleEdit({ id, name, sort }: any) {
        this.form = {
            id,
            name,
            sort
        }
        this.$refs.addUnit.openDialog()
    }

    handleDelete(id: number) {
        apiUnitDel(id).then(() => {
            this.getList()
        })
    }
    handleReset() {
        this.queryObj = {
            name: ''
        }
        this.getList()
    }
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiUnitLists,
            params: this.queryObj
        })
    }

    created() {
        this.getList()
    }
}
